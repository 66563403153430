td.expand-cell, th.expand-cell-header {
  text-align: left;
  color: $gray-600;
}

th.expand-cell-header {
  width: 1rem;
}

td.expanded-cell {
  padding: 1rem 1rem 1rem 2rem !important;
  box-shadow:
    inset 0 11px 8px -10px rgba(0, 0, 0, 0.07),
    inset 0 -11px 8px -10px rgba(0, 0, 0, 0.07);
}
