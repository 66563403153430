//
//
// user-variables.scss
//
// Place your own global variable overrides here, these will override any Bootstrap and theme variables.
@import "falcon/variables";

$font-family-base: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-sans-serif: $font-family-base;

$transition-base: all .2s ease-in-out !default;
$box-shadow-sm: 0 .125rem .25rem rgba($black, .075) !default;

// this fix issue in navbar vertical when link is clicked
// and color is not changing to active
.navbar-vertical .navbar-nav .nav-link.active:hover,
.navbar-vertical .navbar-nav .nav-link.active:focus {
  color: $navbar-light-active-color !important;
}
